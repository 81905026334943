// src/components/PromptInput.js
import React, { useState } from 'react';
import backgroundImage from './image.png';

const PromptInput = ({ onGenerate }) => {
  const [input, setInput] = useState("");

  const handleChange = (event) => {
    setInput(event.target.value);
  };

  const handleClick = () => {
    onGenerate(input);
  };

  return (
    <div>
      <style>{`
        #prompt-container {
          width: 100%;
          height: 450px; 
          background-image: url(${backgroundImage});
          background-size: cover;
          background-position: center;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
        }

        #prompt-wrapper {
          display: flex;
          width: 80%;
        }


        

        
      `}</style>
      <div id="prompt-container">
        <input
          type="text"
          id="prompt"
          placeholder="Enter a prompt"
          value={input}
          onChange={handleChange}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              handleClick();
            }
          }}
        />
        <button id="generate-all-btn" onClick={handleClick}>Generate Images</button>
      </div>
    </div>
  );
};

export default PromptInput;
