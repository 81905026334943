// src/components/Header.js
import React from 'react';

const Header = () => {
  return (
    <h1>CyberDesign</h1>
  );
};

export default Header;
