import React, { useState } from 'react';
import './App.css';
import Header from './components/Header';
import PromptInput from './components/PromptInput';
import Model from './components/Model';
import Footer from './components/Footer';

const apiKeys = [
    "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",
    "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",
    "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",
    "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",
    "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",
    "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",
    "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",
    "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",
    "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",
    "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"
];


const modelUrls = [
    "https://api-inference.huggingface.co/models/stabilityai/stable-diffusion-2-1",
    "https://api-inference.huggingface.co/models/Artples/LAI-ImageGeneration-vSDXL-2",
    "https://api-inference.huggingface.co/models/Melonie/text_to_image_finetuned",
    "https://api-inference.huggingface.co/models/Rajesh2004/text-to-image-ai-model",
    "https://api-inference.huggingface.co/models/kr-manish/text-to-image-sdxl-lora-dreemBooth-rashmika",
    "https://api-inference.huggingface.co/models/shrikant11/pokemon_text_to_image_2",
    "https://api-inference.huggingface.co/models/cloudqi/cqi_text_to_image_pt_v0",
    "https://api-inference.huggingface.co/models/ghadaaa/gen_images",
    "https://api-inference.huggingface.co/models/Yuan274/whale-image-generator",
    "https://api-inference.huggingface.co/models/MY555/surya-fine-tuned-image-generator-V2"
];

const modelLinks = [
    "https://huggingface.co/stabilityai/stable-diffusion-2-1",
    "https://huggingface.co/Artples/LAI-ImageGeneration-vSDXL-2",
    "https://huggingface.co/Melonie/text_to_image_finetuned",
    "https://huggingface.co/Rajesh2004/text-to-image-ai-model",
    "https://huggingface.co/kr-manish/text-to-image-sdxl-lora-dreemBooth-rashmika",
    "https://huggingface.co/shrikant11/pokemon_text_to_image_2",
    "https://huggingface.co/cloudqi/cqi_text_to_image_pt_v0",
    "https://huggingface.co/ghadaaa/gen_images",
    "https://huggingface.co/Yuan274/whale-image-generator",
    "https://huggingface.co/MY555/surya-fine-tuned-image-generator-V2"
];

const App = () => {
  const [prompt, setPrompt] = useState("");

  const handleGenerate = (prompt) => {
    setPrompt(prompt);
  };

  return (
    <div id="container">
      <Header />
      <PromptInput onGenerate={handleGenerate} />
      <div id="models">
        <div className="row">
          {modelUrls.map((url, index) => (
            <Model
              key={index}
              modelUrl={url}
              apiKey={apiKeys[index]}
              prompt={prompt}
              linkUrl={modelLinks[index]}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default App;
