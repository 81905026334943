// src/components/Model.js
import React, { useState, useEffect } from 'react';

const queryWithRetry = async (modelUrl, data, retries = 5, backoff = 3000, token) => {
  for (let i = 0; i < retries; i++) {
    try {
      const startTime = performance.now();
      const queryParam = `?${Date.now()}`;
      const response = await fetch(`${modelUrl}${queryParam}`, {
        headers: { Authorization: `Bearer ${token}` },
        method: "POST",
        body: JSON.stringify(data),
      });
      const endTime = performance.now();
      const responseTime = endTime - startTime;

      if (response.ok) {
        const result = await response.blob();
        return { result, responseTime };
      } else {
        console.error(`Error: ${response.status} ${response.statusText}`);
      }
    } catch (error) {
      console.error(`Fetch error: ${error.message}`);
    }

    await new Promise(resolve => setTimeout(resolve, backoff * Math.pow(2, i)));
  }

  throw new Error('Max retries reached');
};

const Model = ({ modelUrl, apiKey, prompt, linkUrl }) => {
  const [resultImage, setResultImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [responseTime, setResponseTime] = useState(null);

  useEffect(() => {
    if (prompt) {
      setLoading(true);
      setResultImage("");
      setResponseTime(null);

      queryWithRetry(modelUrl, { inputs: prompt }, 5, 3000, apiKey).then(({ result, responseTime }) => {
        const objectUrl = URL.createObjectURL(result);
        setResultImage(objectUrl);
        setResponseTime(responseTime);
        setLoading(false);
      }).catch(error => {
        console.error('Error:', error.message);
        setLoading(false);
      });
    }
  }, [prompt, modelUrl, apiKey]);

  return (
    <div className="model">
      <a href={linkUrl} target="_blank" rel="noopener noreferrer">
        <img className="result" src={resultImage} alt="" />
      </a>
      {loading && <div className="loading">Loading...</div>}
      {responseTime && <div className="response-time">Response Time: {(responseTime / 1000).toFixed(2)}s</div>}
    </div>
  );
};

export default Model;
